<template>
  <div class="planlist-container">
    <div class="planlist-container-select" v-if="!projectName === 'Noor Play'">
      <select
        class="planlist-container-subscription select"
        v-model="planCategory"
        @change="planSelect(planCategory)"
      >
        <option
          v-for="(item, index) in subscriptionsStatus"
          :value="item.value"
          :key="index"
          >{{ item.subscription }}</option
        >
      </select>
    </div>
    <div class="planlist-container-planslist">
      <div
        class="planlist-container-planslist-responsetext"
        v-if="responseError && !isPlans"
      >
        <p>{{ responseError }}</p>
      </div>
      <div
        class="planlist-container-planslist-placeholder"
        v-else-if="!responseError && !isPlans"
      >
        <div
          class="planlist-container-planslist-placeholder-card loading"
          v-for="(item, index) in 3"
          :key="index"
        ></div>
      </div>
      <div
        class="planlist-container-planslist-plans"
        v-else-if="!responseError && isPlans"
      >
        <div v-for="(item, index) in filteredPlanList" :key="index">
          <planCard
            :plan="item"
            :gatewayList="gatewayResponse"
            @subscribe="subscribe"
            @cancel="planCancel"
            @close="(response) => openTelcoOtpPopup(response)"
          ></planCard>
        </div>
        <!-- coupon-code -->
        <div v-if="!subscriptionList.length > 0" class="voucher-container">
          <h2>{{ $t("Have a Voucher? Redeem Now") }}</h2>
          <button class="voucher-button" @click="voucherInit">
            {{ $t("Noorplay Voucher") }}
          </button>
        </div>
        <!-- couponText -->
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { _projectName } from "@/provider-config.js";
import snackbar from "node-snackbar";
import { showSnackBar } from "../../../../utilities";

export default {
  props: {
    locale: {
      type: String,
    },
  },
  data() {
    return {
      subscriptionsStatus: [
        { subscription: this.$t("all plans"), value: "ALL" },
        { subscription: this.$t("active"), value: "ACTIVE" },
        { subscription: this.$t("on trial"), value: "ONTRIAL" },
        { subscription: this.$t("cancelled"), value: "CANCELLED" },
        { subscription: this.$t("pending"), value: "PENDING" },
      ],
      planCategory: "ALL",
      tick: require("@/assets/icons/Tick.svg"),
      responseError: "",
      isPlans: false,
      planStatus: null,
      subscribedPlans: [],
      planList: [],
      filteredPlanList: [],
      subscriptions: [],
      planSelectedForCancellation: null,
      planSelectedForReactivation: null,
      projectName: _projectName,
      gatewayResponse: [],
      isClientcancancel: "",
      subscribedPlanObject: null,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["subscriptionList", "appConfig","newSubscription"]),
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        // this.subscriptions = val;
        this.planSelect(this.planCategory);
      }
    },
    locale(val) {
      if (val) {
        this.planSelect("ALL");
      }
    },
    planList(val) {
      if (val.length > 0) {
        val.forEach((element) => {
          if (element.paymentoptions.includes("OPERATOR")) {
            this.checkGatewayForOperator(this.gatewayResponse, element);
          }
        });
      }
    },
  },
  created() {
    //list gateways.
    eventBus.$emit("listGateway");

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    eventBus.$on("listgateway-response", (response) => {
      console.log("gateway response", response);

      this.$emit("gatewayList", response.data);
      if (!response.reason) {
        this.gatewayResponse = response.data;

        this.setGatewayList(this.gatewayResponse);
        this.planSelect("ALL");
      }
    });
  },
  mounted() {
    //event handler for planlist response.
    eventBus.$on("profile-planlist-response", (response) => {
      console.log("planlist response", response);
      if (response.reason) {
        this.responseError = response.reason;
      } else {
        this.planList = response.data;
        this.subscriptionListSelect();
      }
    });

    //event handler for subscription response.
    eventBus.$on("subscription-response", (response) => {
      console.log("subscription response", response);
      this.subscribedPlans = [];
      if (!response.reason) {
        this.subscribedPlans = response.data;
        this.filterPlans();
      } else if (response.errorcode === 2001 || response.errorcode === 7109) {
        // this.responseError = `No ${this.planStatus} plans found`;
        this.listAllPlans();
        if (this.planStatus === "ALL") {
          // this.checkAllPlanStatus();
        }
      }
    });

    //event handler for subscription update response.
    eventBus.$on("subscriptionUpdate-response", (response) => {
      eventBus.$emit("enableOverlayLoader", false);

      console.log("THE UPDATE RESPONSE", response);

      eventBus.$emit("toggle-cancel-reason-popup", false);

      if (response.reason) {
        // this.responseError = response.reason;

        if (response.statusCode === 401) {
          let payloadData = localStorage.getItem("cancelPopupReasonPayload");

          if (payloadData) {
            payloadData = JSON.parse(payloadData);

            console.log("THE PAYLOAD REASON -- ", payloadData);
            eventBus.$on("re-login-success", () => {
              this.cancelWithReasonFunc(payloadData.data, payloadData.plan);
              eventBus.$off("re-login-success");
            });
            return;
          } else {
            this.snackbarMessage(this.$t("Session Expired. Please try again"));
          }
          localStorage.removeItem("cancelPopupReasonPayload");
        } else {
          this.snackbarMessage(response.reason);
          localStorage.removeItem("cancelPopupReasonPayload");
        }
      } else {
        // this.setNewSubscription(false);
        this.planSelect(this.planCategory);

        //Subscription Updated event for cancel.
        let data;

        console.log(
          "planSelectedForCancellation",
          this.planSelectedForCancellation
        );
        if (this.planSelectedForCancellation) {
          data = {
            Status: "cancelled",
            Subscription_Start_Date: this.planSelectedForCancellation
              .planstartdate,
            Subscription_End_Date: this.planSelectedForCancellation.nextbilling,
            Subscription_Type: this.planSelectedForCancellation.renewtype,
            Plan_Name: this.planSelectedForCancellation.planname,
            // Object_ID: ""
          };
        }

        console.log(
          "New COnfig",
          this.appConfig.indirectCancellationGateways,
          this.subscribedPlanObject
        );

        const messageObj = {
          title: this.$t("Notice!"),
        };

        console.log(
          "NEWW ----- ",
          this.checkIfDirectCancel(),
          this.planSelectedForCancellation,
          this.planSelectedForCancellation.subscriptionstatus === "ACTIVE"
        );

        // if (this.planSelectedForCancellation && this.planSelectedForCancellation.subscriptionstatus !== "ACTIVE") {
        if (
          this.checkIfDirectCancel() &&
          (this.subscribedPlanObject.gwprovider === "INAPP" ||
            this.subscribedPlanObject.gwprovider === "FIRETVINAPP")
        ) {
          const currentMessageObj = this.appConfig.indirectCancellationGateways.find(
            (ele) => ele.gatewayid === this.subscribedPlanObject.gwprovider
          );

          messageObj.message =
            this.localDisplayLang === "eng"
              ? currentMessageObj.message.en
              : currentMessageObj.message.ar;

          eventBus.$emit("show-message-popup", messageObj);
        }

        // }

        //clevertap event
        this.subscriptionUpdatedCleverEvent(data);

        //google analytics event
        this.subscriptionCancelled(data);

        localStorage.removeItem("cancelPopupReasonPayload");

        // eventBus.$off("subscriptionUpdate-response");
      }
    });
  },
  methods: {
    ...mapMutations([
      "setSubscriptionList",
      "setNewSubscription",
      "setGatewayList",
    ]),
    ...mapActions(["getPlan"]),

    voucherInit() {
      this.$router.push({ name: "voucher" });
    },

    snackbarMessage(message) {
      // snackbar.show({
      //   text: this.$t(message),
      //   textColor: "#ffffff",
      //   pos: "bottom-center",
      //   actionTextColor: "#de2600",
      // });

      showSnackBar(this.$t(message));
    },

    checkGatewayForOperator(operators, plan) {
      if (operators.length > 0) {
        let gateways = operators.filter((el) => {
          return el.gwmode == "OPERATOR";
        });

        let index = gateways.findIndex((element) => {
          return (
            element.ApplicaplePlanList.length > 0 &&
            element.ApplicaplePlanList.includes(plan.planid)
          );
        });

        if (index > -1) {
          switch (gateways[index].gatewayid) {
            case "TELCOSTCBH":
              plan.isSTC = true;
              break;
            case "TELOOREDOOQT":
              plan.isOreedo = true;
              break;
            case "TELOOREDOOOM":
              plan.isOreedo = true;
              break;
            case "TIMWEDUUAE":
              plan.isDUUAE = true;
              break;
            case "TELCOZAINKWT":
              plan.isZainKwt = true;
              break;
            case "TPAYVODAFONE":
              plan.isTpay = true;
              break;
            case "TPAYORANGE":
              plan.isTpay = true;
              break;
            case "TPAYWE":
              plan.isTpay = true;
              break;
            case "TPAYETISALAT":
              plan.isTpay = true;
              break;
            case "TELCOZAINJOR":
              plan.isZainJod = true;
              break;
            case "ETISALETUAE":
              plan.isEtisalatUAE = true;
              break;
            case "TELCOREDOOPS":
              plan.isPalestine = true;
              break;
            case "TELCOZAINIRQ":
              plan.isZainIraq = true;
              break;
            case "TELCOZAINKSA":
              plan.isZainKsa = true;
          }
        }
      }
    },
    //function called when user changes the plan status from drop down.
    planSelect(planstatus) {
      this.isPlans = false;
      this.responseError = "";
      this.planStatus = "";
      this.filteredPlanList = [];
      this.subscribedPlans = [];
      this.planList = [];
      this.planStatus = planstatus;

      let payload = {
        planvisibility: "YES",
        //planvisibility: "NO",
        displaylanguage: this.locale,
      };

      eventBus.$emit("planList_profile", payload);
    },

    //emits subscription event based on the plan status.
    subscriptionListSelect() {
      let payload = {
        subscriptionstatus: this.planStatus,
      };
      eventBus.$emit("subscriptionList", payload);
    },

    //filter plans based on plan subscription list.
    filterPlans() {
      this.responseError = "";
      this.filteredPlanList = [];
      this.subscribedPlans.forEach((subscribed) => {
        let index = this.planList.findIndex((element) => {
          return element.planid === subscribed.planid;
        });
        if (index > -1) {
          console.log("response of getplan api -- subbed", subscribed);

          this.subscribedPlanObject = subscribed;

          this.isClientcancancel = subscribed.clientcancancel;

          if (
            subscribed.subscriptionstatus === "ACTIVE" ||
            subscribed.subscriptionstatus === "ONTRIAL"
          ) {
            this.planList[index].buttonStatus = null;
            this.planList[index].subscriptionStatus =
              subscribed.subscriptionstatus;
            this.planList[index].expiry = subscribed.nextbilling;
            this.planList[index].gwprovider = subscribed.gwprovider;
            this.planList[index].clientcancancel = subscribed.clientcancancel;
          } else if (subscribed.subscriptionstatus === "CANCELLED") {
            if (!this.planList[index].paymentoptions.includes("OPERATOR")) {
              this.planList[index].clientcanreactivate =
                subscribed.clientcanreactivate;
              this.planList[index].buttonStatus = "REACTIVATE";
            }
            this.planList[index].subscriptionStatus =
              subscribed.subscriptionstatus;
            this.planList[index].expiry = subscribed.nextbilling;
          }

          console.log("THE PLAN LOG - 2 --> ", this.planList, index);
          this.filteredPlanList.push(this.planList[index]);

          this.isPlans = true;
        } else {
          //call plan with planid.
          let payload = {
            planid: subscribed.planid,
          };
          this.getPlan(payload)
            .then((response) => {
              if (!response.reason) {
                let plan = response.data;
                console.log("response of getplan api", plan);
                if (
                  subscribed.subscriptionstatus === "ACTIVE" ||
                  subscribed.subscriptionstatus === "ONTRIAL"
                ) {
                  plan.buttonStatus = null;
                  plan.subscriptionStatus = subscribed.subscriptionstatus;
                  plan.expiry = subscribed.nextbilling;
                  plan.gwprovider = subscribed.gwprovider;
                  plan.clientcancancel = subscribed.clientcancancel;
                } else if (subscribed.subscriptionstatus === "CANCELLED") {
                  if (!plan.paymentoptions.includes("OPERATOR")) {
                    plan.buttonStatus = "REACTIVATE";
                  }
                  plan.subscriptionStatus = subscribed.subscriptionstatus;
                  plan.expiry = subscribed.nextbilling;
                }
                this.isClientcancancel = subscribed.clientcancancel;

                console.log("THE PLAN LOG - 3 --> ", plan);
                this.filteredPlanList.push(plan);

                this.isPlans = true;
              }
            })
            .catch((error) => console.log("error", error));
        }
      });
    },

    //function called when user selects ALL plan status.
    checkAllPlanStatus() {
      this.responseError = "";

      //by default adding subscribe button to all plans.
      this.planList.forEach((element) => {
        element.buttonStatus = "Subscribe";
      });

      if (this.subscribedPlans.length > 0) {
        this.subscribedPlans.forEach((subscribed) => {
          //finding the matched planindex.
          let index = this.planList.findIndex((element) => {
            return element.planid === subscribed.planid;
          });

          if (index > -1) {
            if (subscribed.subscriptionstatus === "ACTIVE") {
              this.planList[index].buttonStatus = null;
              this.planList[index].subscriptionStatus =
                subscribed.subscriptionstatus;
              this.planList[index].expiry = subscribed.nextbilling;
            } else if (subscribed.subscriptionstatus === "CANCELLED") {
              this.planList[index].buttonStatus = "REACTIVATE";
              this.planList[index].subscriptionStatus =
                subscribed.subscriptionstatus;
              this.planList[index].expiry = subscribed.nextbilling;

              if (this.subscriptionList.length > 0) {
                let removeIndex = this.subscriptionList.findIndex((element) => {
                  return element.planid === this.planList[index].planid;
                });

                // if (removeIndex > -1) {
                //   this.subscriptionList.splice(index, 1);
                //   this.setSubscriptionList(this.subscriptionList);
                // }
              }
            }
          }
        });
      }
      console.log("====|||", this.planList);
      console.log("THE PLAN LOG - 4 --> ", this.planList);

      this.filteredPlanList = this.planList;
      this.isPlans = true;

      //check for active plan
    },

    //list all plans if no subscription present(only for noor play)
    listAllPlans() {
      this.filteredPlanList = [];
      this.planList.forEach((plan) => {
        plan.buttonStatus = "Subscribe";

        //check plan contains STC Bharain plan.
        // plan.paymentoptions.includes("OPERATOR")
        //   ? (plan.isSTC = true)
        //   : (plan.isSTC = false);

        //filter STC plans.
        // if (!plan.isSTC) {
        //   this.filteredPlanList.push(plan);
        // }
      });

      let planPrice = localStorage.getItem("appConfig")
        ? JSON.parse(localStorage.getItem("appConfig")).planPrice
        : [];
      let planInfoDetails = this.planList;

      this.planList.forEach((item, index) => {
        if (planPrice && planPrice.length > 0) {
          planPrice.forEach((discountData) => {
            if (discountData.planid == item.planid) {
              this.planList[index].discountData =
                discountData.originalprice && discountData.originalprice[0]
                  ? discountData.originalprice[0]
                  : {};
            }
          });
        }
      });
      console.log("THE PLAN LOG - 5 --> ", this.planList);

      this.filteredPlanList = this.planList;
      this.isPlans = true;
    },
    //list all subscribed plans if subscription present(only for noor play)

    //to subscribe for any plan.
    subscribe(plan) {
      if (plan.buttonStatus === "Subscribe") {
        let payload = {
          state: true,
          detail: plan,
          gateways: this.gatewayResponse,
          transactionPurpose: "SUBSCRIPTION",
          coupon: plan.coupon ? plan.coupon : null,
        };
        // eventBus.$emit("noorPayments", payload);
        eventBus.$emit("paymentPopup", payload);
      } else if (plan.buttonStatus === "REACTIVATE") {
        eventBus.$emit("enableOverlayLoader", true);
        let payload = {
          planid: plan.planid,
          params: {
            planstatus: "ACTIVE",
          },
        };
        eventBus.$emit("subscription-update", payload);
      }
    },
    //to perform plan cancel.
    planCancel(plan) {
      eventBus.$emit("toggle-cancel-reason-popup", true);

      // const isDirectCancel = this.checkIfDirectCancel();

      eventBus.$on("submit-cancel-reponse-event", (data) => {
        const payloadData = { data, plan };

        localStorage.setItem(
          "cancelPopupReasonPayload",
          JSON.stringify(payloadData)
        );

        this.cancelWithReasonFunc(data, plan);
      });
    },

    checkIfDirectCancel() {
      let status = false;

      console.log("THE VALUE IS COMING IN ", this.isClientcancancel);

      if (this.isClientcancancel == "YES") {
        status = false;
      } else {
        status = true;
      }

      // this.appConfig.indirectCancellationGateways.forEach((gateway) => {
      //   this.subscriptionList.forEach((sub) => {
      //     console.log("gwprovider -- ", sub.gwprovider, gateway.gatewayid);
      //     if (sub.gwprovider === gateway.gatewayid) {
      //       status = true;
      //     }
      //   });
      // });

      return status;
    },

    cancelWithReasonFunc(data, plan) {
      const isDirectCancel = this.checkIfDirectCancel();

      let currentPlanStatus = "";

      if (isDirectCancel) {
        currentPlanStatus = "ACTIVE";
      } else {
        currentPlanStatus = "CANCEL";
      }

      this.planSelectedForCancellation = null;
      let index = this.subscriptionList.findIndex((el) => {
        return el.planid === plan.planid;
      });
      if (index > -1) {
        this.planSelectedForCancellation = this.subscriptionList[index];
      }
      eventBus.$emit("enableOverlayLoader", true);

      let payload = {
        planid: plan.planid,
        params: {
          planstatus: currentPlanStatus,
          comment: data.reason ? data.reason : "",
        },
      };

      console.log("THE FINAL CANCEL PAYLOAD --- ", payload);

      eventBus.$emit("subscription-update", payload);
    },

    openTelcoOtpPopup(response) {
      eventBus.$emit("telcoOtpPopup", response);
    },
  },
  components: {
    planCard: () =>
      import(
        /* webpackChunkName: "plancard" */ "@/components/Templates/planCard.vue"
      ),
  },
  beforeDestroy() {
    eventBus.$off("listgateway-response");
    eventBus.$off("profile-planlist-response");
    eventBus.$off("subscription-response");
    eventBus.$off("subscriptionUpdate-response");
    eventBus.$off("submit-cancel-reponse-event");
  },
  mixins: [Utility, googleAnalytics, cleverTap],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.planlist-container {
  padding: 0 20px;

  &-select {
    margin-bottom: 20px;
  }

  &-subscription {
    width: 280px;
    padding: 10px 0px;
    border: none;
    border-bottom: 1px solid $clr-light-gd3;
    color: #ffffff;
    font-weight: 500;
    -webkit-appearance: none;
    background: url("../../../../assets/icons/down_arrow.svg") no-repeat right;
  }

  &-planslist {
    &-responsetext {
      text-align: center;

      p {
        color: #ffffff;
        font-weight: bold;
      }
    }

    &-placeholder {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-card {
        background-color: #0d0d0d;
        border-radius: 10px;
        height: 350px;
        width: 300px;
        margin: 25px 25px;
        overflow: hidden;
      }
    }

    &-plans {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      row-gap: 15px;

      .voucher-container {
        color: #ffffff;
        padding: 2rem;
        border-radius: 10px;
        background-color: #0d0d0d;
        height: 150px;
        width: 300px;
        position: relative;
        margin-right: 20px;
        cursor: pointer;

        h2 {
          font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
          font-size: 0.8rem;
          margin-bottom: 1rem;
          font-weight: 600;
          display: flex;
        }

        .voucher-button {
          display: flex;
          background-color: #1c1c1e;
          color: #ffffff;
          font-size: 0.9rem;
          font-weight: 600;
          padding: 10px 20px;
          border: 2px solid #560C1D;
          border-radius: 8px;
          cursor: pointer;
          outline: none;
          margin-top: 33px;
          width: 14.5rem;
        }

        .voucher-button:hover {
          background-color: #560C1D;
          color: #ffffff;
        }
      }
    }
  }
}

/* Mobile (small screens) */
@media only screen and (max-width: 375px) {
  .planlist-container {
    padding: 10px 15px;
    width: 100%;

    &-planslist {
      &-placeholder {
        display: block;
        justify-content: center;
        align-items: center;

        &-card {
          width: 100%; /* Full width for mobile */
          height: auto;
          margin: 15px 0;
        }
      }

      &-plans {
        justify-content: center;
      }
    }

    &-subscription {
      width: 100%; /* Full width for mobile */
      margin-bottom: 15px;
    }
  }
}

/* Medium-sized mobile (landscape, 360px - 600px) */
@media only screen and (min-width: 360px) and (max-width: 600px) {
  .planlist-container {
    width: 100%;
    padding: 10px;

    &-planslist {
      &-placeholder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        &-card {
          width: 48%; /* Two items per row */
          height: 280px;
          margin: 10px 0;
        }
      }

      &-plans {
        justify-content: center;
      }
    }

    &-subscription {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

/* Large Mobile (Tablet, 601px - 768px) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .planlist-container {
    width: 100%;
    padding: 20px;

    &-planslist {
      &-placeholder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-card {
          width: 48%; /* Two items per row */
          height: 320px;
          margin: 15px;
        }
      }

      &-plans {
        justify-content: flex-start;
        row-gap: 15px;
      }
    }
  }
}

/* Large Screen Adjustments (above 1100px) */
@media only screen and (min-width: 1100px) {
  .planlist-container {
    padding: 20px;

    &-planslist {
      &-placeholder {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap; /* Prevent wrapping on large screens */
        
        &-card {
          width: 30%; /* Three items per row */
          height: 350px;
          margin: 20px;
        }
      }

      &-plans {
        justify-content: flex-start;
        row-gap: 15px;
      }
    }
  }
}

/* Extra Large Screen (Desktop) */
@media only screen and (min-width: 1500px) {
  .planlist-container {
    padding: 40px;

    &-planslist {
      &-placeholder {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        
        &-card {
          width: 25%; /* Four items per row */
          height: 400px;
          margin: 25px;
        }
      }

      &-plans {
        justify-content: flex-start;
      }
    }
  }
}

</style>
