<template>
  <div class="plan">
    <div class="plan-card">
      <div class="plan-card-detail">
        <div
          class="plan-card-detail-amount-container"
          style="text-decoration: line-through"
        >
          {{
            plan.discountData && plan.discountData.currency
              ? plan.discountData.currency
              : ""
          }}
          {{
            plan.discountData && plan.discountData.price
              ? plan.discountData.price / 100
              : ""
          }}
        </div>

        <div
          v-if="getPlanMapFromConfig(plan)"
          class="discount-text-msg"
          :dir="localDisplayLang === 'ara' ? 'ltr' : 'ltr'"
        >
          {{ $t(planAndDiscount.discountPercentage) }} {{ $t("Discount") }}
        </div>

        <div
          v-if="getPlanMapFromConfig(plan)"
          class="plan-card-detail-amount-container older-price"
        >
          <div
            class="plan-card-detail-amount-container-label"
            v-if="localDisplayLang === 'ara'"
            style="display: flex"
          >
            <div
              class="plan-card-detail-amount-container-label-container discount"
            >
              <span
                class="
                  plan-card-detail-amount-container-label-container-symbol
                  discount-text line-through
                "
                >{{ plan.currency }}</span
              >
              <span
                class="
                  plan-card-detail-amount-container-label-container-price
                  discount-text line-through
                "
                >{{ planAndDiscount.actualPrice }}</span
              >
            </div>
            <!-- <span class="plan-card-detail-amount-container-label-interval-ar discount-text">
              {{ plan.planinterval === "QUARTER" ? "3" + " " + $t("quarter") : $t(plan.planinterval) }}
            </span> -->
          </div>
          <div
            class="plan-card-detail-amount-container-label"
            v-else
            style="display: flex"
          >
            <div
              class="plan-card-detail-amount-container-label-container discount"
            >
              <span
                class="
                  plan-card-detail-amount-container-label-container-symbol
                  discount-text line-through
                "
                >{{ plan.currency }}</span
              >
              <span
                class="
                  plan-card-detail-amount-container-label-container-price
                  discount-text line-through
                "
                >{{ planAndDiscount.actualPrice }}</span
              >
            </div>
            <!-- <span class="plan-card-detail-amount-container-label-interval-ar discount-text">
              {{ plan.planinterval === "QUARTER" ? "3" + " " + $t("quarter") : $t(plan.planinterval) }}
            </span> -->
          </div>
        </div>

        <div class="plan-card-detail-amount-container">
          <div
            class="plan-card-detail-amount-container-label"
            v-if="localDisplayLang === 'ara'"
            style="display: flex"
          >
            <div class="plan-card-detail-amount-container-label-container">
              <!-- <span class="plan-card-detail-amount-container-label-container-symbol">{{ plan.currency }}</span> -->
							  <span
                class="plan-card-detail-amount-container-label-container-symbol"
                v-if="plan.currency == `ILS`"
                >{{ planCardCurrency }}</span
              >
              <span
							 v-else
                class="plan-card-detail-amount-container-label-container-symbol"
                >{{ plan.currency }}</span>
              <!-- <span class="plan-card-detail-amount-container-label-container-price">{{ plan.amount / 100 }}</span> -->
              <span
                v-if="!getPlanMapFromConfig(plan) && plan.currency !== `ILS`"
                :class="{ discount: getPlanMapFromConfig(plan) }"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ plan.amount / 100 }}
              </span>
							 <span
                v-if="!getPlanMapFromConfig(plan) && plan.currency == `ILS` && plan.planinterval == `MONTH`"
                :class="{ discount: getPlanMapFromConfig(plan) }"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ plancardPriceMonthly / 100 }}
              </span>
							 <span
                v-if="!getPlanMapFromConfig(plan) && plan.currency == `ILS` && plan.planinterval == `WEEK`"
                :class="{ discount: getPlanMapFromConfig(plan) }"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ plancardPriceWeekly / 100 }}
              </span>
              <span
                v-if="getPlanMapFromConfig(plan)"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ planAndDiscount.offerPrice / 100 }}</span
              >
            </div>
            <span class="plan-card-detail-amount-container-label-interval-ar"
              >/ &nbsp;
              {{
                plan.planinterval === "QUARTER"
                  ? "3" + " " + $t("quarter")
                  : $t(plan.planinterval)
              }}
            </span>
          </div>

          <div
            class="plan-card-detail-amount-container-label"
            style="display: flex"
            v-else
          >
            <div class="plan-card-detail-amount-container-label-container">
              <span
                class="plan-card-detail-amount-container-label-container-symbol"
                v-if="localDisplayLang === 'eng' && plan.currency == `ILS`"
                >{{ planCardCurrency }}</span
              >
							<span
                class="plan-card-detail-amount-container-label-container-symbol"
                v-if="localDisplayLang === 'eng' && plan.currency !== `ILS`"
                >{{ plan.currency }}</span
              >
              <span
                v-if="!getPlanMapFromConfig(plan) && plan.currency !== `ILS`"
                :class="{ discount: getPlanMapFromConfig(plan) }"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ plan.amount / 100 }}
              </span>
							<span
                v-if="!getPlanMapFromConfig(plan) && plan.currency == `ILS` && plan.planinterval == `MONTH` "
                :class="{ discount: getPlanMapFromConfig(plan) }"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ plancardPriceMonthly / 100 }}
              </span>
							<span
                v-if="!getPlanMapFromConfig(plan) && plan.currency == `ILS` && plan.planinterval == `WEEK`"
                :class="{ discount: getPlanMapFromConfig(plan) }"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ plancardPriceWeekly / 100 }}
              </span>
              <span
                v-if="getPlanMapFromConfig(plan)"
                class="plan-card-detail-amount-container-label-container-price"
                >{{ planAndDiscount.offerPrice / 100 }}</span
              >
            </div>
            <span class="plan-card-detail-amount-container-label-interval"
              >/ &nbsp;{{
                plan.planinterval === "QUARTER"
                  ? $t("quarter")
                  : $t(plan.planinterval)
              }}</span
            >
          </div>
        </div>
        <p class="plan-card-detail-planname">{{ $t(plan.planname) }}</p>
      </div>

      <div
        class="plan-card-trailperiod"
        v-if="
          plan.trialperioddays > 0 &&
          plan.trialperioddays === 1 &&
          plan.subscriptionStatus !== 'CANCELLED'
        "
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      >
        <p>{{ plan.trialperioddays }} {{ $t("1trialdays") }}</p>
      </div>

      <div
        class="plan-card-trailperiod"
        v-if="
          plan.trialperioddays >= 2 && plan.subscriptionStatus !== 'CANCELLED'
        "
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      >
        <p>{{ plan.trialperioddays }} {{ $t("trialdays") }}</p>
      </div>

      <!-- <div class="plan-card-trailperiod" v-if="(plan.trialperioddays > 2 && plan.trialperioddays === 7 && plan.subscriptionStatus !== 'CANCELLED')" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <p>{{ plan.trialperioddays }} {{ $t('7trialdays') }}</p>
      </div> -->

      <div
        :class="[
          localDisplayLang === 'eng'
            ? 'plan-card-detail-plantag'
            : 'plan-card-detail-plantag-ar',
        ]"
        v-if="planTag"
      >
        <p>{{ localDisplayLang === "ara" ? planTag.ar : planTag.en }}</p>
      </div>

      <div class="plan-card-features">
        <ul class="light-text features">
          <li class="feature-items">
            <img :src="tick" alt />
            <span v-if="localDisplayLang === 'eng'">{{ planCardBenfits.hintOneEn}}</span>
						<span v-if="localDisplayLang === 'ara'">{{ planCardBenfits.hintOneAr}}</span>
          </li>
          <li class="feature-items">
            <img :src="tick" alt />
						<span v-if="localDisplayLang === 'eng'">{{ planCardBenfits.hintTwoEn }}</span>
						<span v-if="localDisplayLang === 'ara'">{{ planCardBenfits.hintTwoAr}}</span>
          </li>
          <li class="feature-items">
            <img :src="tick" alt />
						<span v-if="localDisplayLang === 'eng'">{{ planCardBenfits.hintThreeEn }}</span>
						<span v-if="localDisplayLang === 'ara'">{{ planCardBenfits.hintThreeAr }}</span>
          </li>
           <li class="feature-items">
            <img :src="tick" alt />
						<span v-if="localDisplayLang === 'eng'">{{ planCardBenfits.hintFourEn }}</span>
						<span v-if="localDisplayLang === 'ara'">{{ planCardBenfits.hintFourAr }}</span>
          </li>
          <li class="feature-items">
            <img :src="tick" alt />
						<span v-if="localDisplayLang === 'eng'">{{ planCardBenfits.hintFiveEn }}</span>
						<span v-if="localDisplayLang === 'ara'">{{ planCardBenfits.hintFiveAr }}</span>
          </li>

          <li v-if="getPlanMapFromConfig(plan)" class="plan-disclimar-text">
            <span v-if="localDisplayLang === 'eng'">
              {{ planAndDiscount.disclaimer.eng }}
            </span>
            <span v-else> {{ planAndDiscount.disclaimer.ara }} </span>
          </li>
        </ul>
      </div>

      <div class="plan-card-paymentMethods">
        <img
          :src="cardLogoForOperator(plan)"
          alt=""
          :class="[
            plan.isSTC
              ? 'stc'
              : plan.isOreedo
              ? 'oreedo'
              : plan.isEtisalatUAE
              ? 'etisalat'
              : 'duuae',
          ]"
          v-if="
            plan.isOreedo ||
            plan.isSTC ||
            plan.isDUUAE ||
            plan.isZainKwt ||
            plan.isZainKsa ||
            plan.isZainJod ||
            plan.isEtisalatUAE || 
						plan.isPalestine ||
            plan.isZainIraq
          "
        />
        <div class="logo_container" v-else>
          <!-- <img
            src="@/assets/icons/Paypal-long.png"
            class="paypal_logo"
            alt="paypal"
          />
          <img
            src="@/assets/icons/Card_2.svg"
            class="card_logo"
            alt="checkout"
          /> -->
          <!-- <img
            v-if="showApplePayLogo"
            src="@/assets/icons/apple-pay-logo.svg"
            class="card_logo"
            alt="checkout"
          /> -->
          <div class="apple-pay">
            <apple-pay-button
              buttonstyle="black"
              type="plain"
              locale="en"
            ></apple-pay-button>
          </div>

          <img
            v-if="appConfig.featureEnabled.paypalEnabled"
            src="@/assets/icons/Paypal-long.png"
            class="paypal_logo"
            alt="paypal"
          />
          <img
            v-if="showCreditCard"
            src="@/assets/icons/Card_2.svg"
            class="card_logo"
            alt="checkout"
          />
        </div>
      </div>

      <div class="plan-card-subscribe">
        <div
          class="plan-card-subscribe-expiry-title"
          v-if="plan.subscriptionStatus === 'ACTIVE'"
        >
          <span class="expiry">{{ $t("renewal date") }}</span
          >&nbsp;
          <span class="expiry" dir="ltr">{{
            displayDateFormat(plan.expiry)
          }}</span>
        </div>

        <div
          class="plan-card-subscribe-cancelled"
          v-if="plan.subscriptionStatus === 'CANCELLED'"
        >
          <p class="expiry">{{ $t("cancelled") }}</p>
        </div>

        <div
          class="plan-card-subscribe-expiry-title"
          v-if="plan.subscriptionStatus === 'CANCELLED'"
        >
          <div>
            <span class="expiry">{{ $t("expires on") }}</span
            >&nbsp;
            <span class="expiry" dir="ltr">{{
              displayDateFormat(plan.expiry)
            }}</span>
          </div>
        </div>

        <button
          v-if="
            (plan.buttonStatus === 'Subscribe' || getReactivateButtonStatus) &&
            !showLoader
          "
          class="button-primary plan-card-subscribe-btn"
          :class="[
            providerUniqueId === 'z01x1wPo'
              ? 'primary-nammaflix-button'
              : 'primary-net5-button',
            planTag ? 'yellow-color-btn' : ''
          ]"
          @click="planSubscribe(plan)"
        >
          <span class="btn-txt-color">{{ $t(plan.buttonStatus) }}</span>
        </button>

        <div v-if="showLoader">
          <loading></loading>
        </div>
      </div>
      <!-- <div v-if="getPlanMapFromConfig(plan)" class="plan-disclimar-text">
         <span v-if="localDisplayLang === 'eng'">  {{ planAndDiscount.disclaimer.eng }} </span>
         <span v-else>  {{ planAndDiscount.disclaimer.ara }} </span>
      </div> -->
    </div>
    <div class="plan-cancel" v-if="getCancelButtonStatus">
      <div class="plan-cancel-card">
        <p @click="planCancel(plan)">{{ $t("Cancel plan") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import Loading from "./Loading.vue";
import snackbar from "node-snackbar";
import "../../.././node_modules/node-snackbar/dist/snackbar.css";
import { store } from "@/store/store";

import googleAnalytics from "./../../mixins/googleAnalytics";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import { showSnackBar } from "../../utilities";

export default {
  components: { Loading },
  props: {
    plan: {
      type: Object,
    },
    gatewayList: {
      type: Array,
    },
  },
  data() {
    return {
      tick: require("@/assets/icons/Tick.svg"),
      providerUniqueId: _providerId,
      localDisplayLang: null,
      telcoGateway: null,
      showPaymentLabels: false,
      telcoGateway: null,
      operatorGateway: null,
      planTag: null,
      showLoader: false,
      mobileNumber: null,
      planAndDiscount: null,
      ApplePaySession: window.ApplePaySession,
      sessionToken: null,
      showApplePayLogo: false,
      showCreditCard: true,
			planCardBenfits: null,
      currentUserCountry: null,
      subscriberDetails: null,
			plancardPrice: null,
			plancardPriceMonthly: null,
			plancardPriceWeekly: null

    };
  },
  mounted() {
    this.showCreditCardMethod();
  },
  computed: {
    ...mapGetters(["getRtl", "appConfig"]),

    getCancelButtonStatus() {
      console.log("THE CANCEL LOG --> ", this.plan);

      if (
        this.plan.gwprovider &&
        (this.plan.subscriptionStatus === "ACTIVE" ||
          this.plan.subscriptionStatus === "ONTRIAL") &&
        (this.plan.clientcancancel === "YES" ||
          (this.plan.clientcancancel === "NO" &&
            this.getIndirectCancellationGateways))
      ) {
        return true;
      }
    },

    getReactivateButtonStatus() {
      if (
        this.plan.buttonStatus === "REACTIVATE" &&
        this.plan.subscriptionStatus === "CANCELLED" &&
        this.plan.clientcanreactivate === "YES"
      ) {
        return true;
      }
    },

    getIndirectCancellationGateways() {
      let gatewayExists = false;
      this.appConfig.indirectCancellationGateways.forEach((ele) => {
        if (ele.gatewayid === this.plan.gwprovider) {
          gatewayExists = true;
        }
      });

      return gatewayExists;
    },
  },
  created() {
    console.log("THE PLAN STATUS", this.plan);

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    (this.sessionToken = localStorage.getItem("sessionToken")),
    console.log("THIS IS THE PLAN -- ", this.plan.isEtisalatUAE);
    this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")) ? JSON.parse(localStorage.getItem("subscriberDetails")) : null;
    console.log("subscriberDetails", this.subscriberDetails);
    let subscribedUserCountry = this.subscriberDetails ? this.subscriberDetails.data?.subscribercountry : null;
    console.log("77777", subscribedUserCountry);
    this.currentUserCountry = localStorage.getItem("currentUserCountry")
        ? JSON.parse(localStorage.getItem("currentUserCountry"))
        : null;
    console.log("8888", this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode], this.appConfig.planDiscount["AU"])
    this.planAndDiscount = this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] ? this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] : this.appConfig.planDiscount["DEFAULT"];
    console.log("this.planAndDiscount", this.currentUserCountry, this.planAndDiscount);
    this.planCardBenfits = this.appConfig.planCardBenfits;
		this.plancardPriceMonthly = this.appConfig.plancardPrice.monthlyprice;
		this.plancardPriceWeekly = this.appConfig.plancardPrice.weeklyprice;
		this.planCardCurrency = this.appConfig.plancardPrice.currency;

    //fetch plantag from config.
    console.log("0000", this.appConfig.planTags);
    this.planTag = this.appConfig.planTags[this.plan.planid]
      ? this.appConfig.planTags[this.plan.planid]
      : "";

    //fetch oreedo plan-card-detail-amount-container-label-container-price
    if (
      (this.plan.hasOwnProperty("isSTC") && this.plan.isSTC) ||
      (this.plan.hasOwnProperty("isOreedo") && this.plan.isOreedo) ||
      (this.plan.hasOwnProperty("isDUUAE") && this.plan.isDUUAE) ||
      (this.plan.hasOwnProperty("isZainKwt") && this.plan.isZainKwt) ||
      (this.plan.hasOwnProperty("isTpay") && this.plan.isTpay) ||
      (this.plan.hasOwnProperty("isZainJod") && this.plan.isZainJod) ||
      (this.plan.hasOwnProperty("isEtisalatUAE") && this.plan.isEtisalatUAE) ||
			(this.plan.hasOwnProperty("isPalestine") && this.plan.isPalestine) ||
      (this.plan.hasOwnProperty("isZainIraq") && this.plan.isZainIraq) ||
      (this.plan.hasOwnProperty("isZainKsa") && this.plan.isZainKsa)
    ) {
      this.showPaymentLabels = true;
      this.setupOperatorGateway(this.plan);
    }

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    eventBus.$on("free-subscription-response", (response) => {
      if (this.$route.fullPath === "/viewAllPlans") {
        this.$router.push("/");
      }
      setTimeout(() => {
        if (this.showPaymentLabels) {
          eventBus.$emit("toggleSuccessMessagePopupTrigger", {
            response,
            paymentDetail: this.plan,
          });
        }
      }, 500);
    });
  },
  methods: {
    ...mapActions(["paymentInitiation"]),

    getPlanMapFromConfig(plan) {
      if (
        plan &&
        this.planAndDiscount &&
        plan.planid === this.planAndDiscount.planid
      ) {
        return true;
      }
    },
    showCreditCardMethod() {
      if (localStorage.getItem("currentUserCountry")) {
        this.currentUserCountry = localStorage.getItem("currentUserCountry")
          ? JSON.parse(localStorage.getItem("currentUserCountry"))
          : null;
        let filterCountries = this.appConfig.countryFilter;
        console.log(
          filterCountries.includes(this.currentUserCountry.CountryCode)
        );
        if (filterCountries.includes(this.currentUserCountry.CountryCode)) {
          this.showCreditCard = false;
        }
      }
    },

    cardLogoForOperator(plan) {
      let STCLogo = require("@/assets/icons/STC.svg");
      let OreedoLogo = require("@/assets/icons/ooredoo-logo.svg");
      let DUUAELogo = require("@/assets/icons/du_uae.png");
      let zainLogo = require("@/assets/icons/zain_logo.svg");
      let etisalatUAE = require("@/assets/icons/etisalat-logo-new.png");
			let ooredoPlaestine = require("@/assets/icons/oredoo-palestine.png");
      let zainIraqLogo = require("@/assets/icons/oredoo-palestine.png");


      console.log("THE PLAN LOGO -- ", plan);

      if (plan.isSTC) {
        return STCLogo;
      } else if (plan.isOreedo) {
        return OreedoLogo;
      } else if (plan.isDUUAE) {
        return DUUAELogo;
      } else if (plan.isZainKwt || plan.isZainJod || plan.isZainKsa || plan.isZainIraq) {
        return zainLogo;
      } else if (plan.isEtisalatUAE) {
        return etisalatUAE;
      } else if (plan.isPalestine) {
        return ooredoPlaestine;
      } 
    },

    setupOperatorGateway(plan) {
      let operatorGateways = this.gatewayList.filter((el) => {
        return el.gwmode == "OPERATOR";
      });

      let index = operatorGateways.findIndex((element) => {
        return (
          element.ApplicaplePlanList.length > 0 &&
          element.ApplicaplePlanList.includes(plan.planid)
        );
      });

      if (index > -1) {
        this.operatorGateway = operatorGateways[index];
      }
    },

    planSubscribe(item) {
      console.log("planSub",item)
      if (!this.sessionToken) {
        const newItem = {
          ...item,
        };

        if (
          item &&
          this.planAndDiscount &&
          item.planid === this.planAndDiscount.planid
        ) {
          newItem.coupon = this.planAndDiscount.coupon;
        }

        this.$emit("subscribe", newItem);
        return;
      }
      if (
        (item.hasOwnProperty("isSTC") && item.isSTC) ||
        (item.hasOwnProperty("isOreedo") && item.isOreedo) ||
        (item.hasOwnProperty("isDUUAE") && item.isDUUAE) ||
        (item.hasOwnProperty("isZainKwt") && item.isZainKwt) ||
        (item.hasOwnProperty("isTpay") && item.isTpay) ||
        (item.hasOwnProperty("isZainJod") && item.isZainJod) ||
				(item.hasOwnProperty("isPalestine") && item.isPalestine) ||
        (item.hasOwnProperty("isEtisalatUAE") && item.isEtisalatUAE) ||
         (item.hasOwnProperty("isZainIraq") && item.isZainIraq) ||
        (item.hasOwnProperty("isZainKsa") && item.isZainKsa)
      ) {
        this.subscriptionPrecheck(item);
      } else {
        this.initiatePayment(item);
      }
    },

    precheckResponse(plan) {
      let payload = {
        transactionpurpose: "SUBSCRIPTION",
        planid: plan.planid,
      };
      this.showLoader = true;
      return store.dispatch("subscriptionPrecheck", payload);
    },

    subscriptionPrecheck(plan) {
      this.precheckResponse(plan)
        .then((response) => {
          if (response.reason) {
            this.showLoader = true;
            this.snackbarMessage(response.reason);
          } else {
            console.log("THE PRECHECK TELCO --> ", response, plan);

            if (response.data && response.data.finalamount === 0) {
              let payload = {
                transactionpurpose: "SUBSCRIPTION",
                planid: response.data.planid,
                availabilityid: plan.availabilityset[0],
              };

              eventBus.$emit("create-free-subscription", payload);

              return;
            }

            if (
              (plan.hasOwnProperty("isSTC") && plan.isSTC) ||
              (plan.hasOwnProperty("isZainKwt") && plan.isZainKwt) ||
              (plan.hasOwnProperty("isZainJod") && plan.isZainJod)
            ) {
              this.initiatePayment(plan);
            } else {
              //check for mobile number is there in subscriber details or not.
              let subscriberDetails = JSON.parse(
                localStorage.getItem("subscriberDetails")
              ).data;
              // subscriberDetails.mobileno = "+96895018020";
              if (subscriberDetails) {
                if (!subscriberDetails.mobileno) {
                  this.showLoader = false;
                  let payload = {
                    isMobileno: false,
                    planInfo: plan,
                    gatewayInfo: this.operatorGateway,
                    state: true,
                  };
                  this.$emit("close", payload);
                } else {
                  this.mobileNumber = subscriberDetails.mobileno;
                  this.initiatePayment(plan);
                }
              }
            }
          }
        })
        .catch((error) => console.log(error));
    },

    constructInitParams(item) {
      let payload = {
        amount: item.amount,
        currency: item.currency,
        devicetype: "WEB",
        transactionpurpose: "SUBSCRIPTION",
        transactionmode: "CC",
        availabilityid: item.availabilityset[0],
        planid: item.planid,
      };

      if (
        this.mobileNumber &&
        ((item.hasOwnProperty("isOreedo") && item.isOreedo) ||
          (item.hasOwnProperty("isZainIraq") && item.isZainIraq) ||
          (item.hasOwnProperty("isDUUAE") && item.isDUUAE) ||
          (item.hasOwnProperty("isTpay") && item.isTpay) ||
          (item.hasOwnProperty("isEtisalatUAE") && item.isEtisalatUAE) ||
					(item.hasOwnProperty("isPalestine") && item.isPalestine)) ||
          (item.hasOwnProperty("isZainKsa") && item.isZainKsa)) {
        payload.mobileno = this.mobileNumber;
      }

      return payload;
    },

    initiatePayment(item) {
      if (
        (item.hasOwnProperty("isSTC") && item.isSTC) ||
        (item.hasOwnProperty("isZainKwt") && item.isZainKwt) ||
        (item.hasOwnProperty("isZainJod") && item.isZainJod)
      ) {
        let currentDomain = window.location.origin;

        let payload = {
          gatewayid: this.operatorGateway.gatewayid,
          params: this.constructInitParams(item),
        };

        if (
          (item.hasOwnProperty("isZainKwt") && item.isZainKwt) ||
          (item.hasOwnProperty("isZainJod") && item.isZainJod)
        ) {
          payload.params.client_return_url =
            currentDomain + "/profile" + "?" + "planid=" + item.planid;
        } else {
          payload.params.return_url =
            currentDomain + "/profile" + "?" + "planid=" + item.planid;
        }

        this.paymentInitiation(payload)
          .then((response) => {
            console.log(
              "payment init response from plan card for TELCO",
              response
            );
            this.showLoader = false;
            if (response.data.reason) {
              this.snackbarMessage(response.data.reason);
            } else {
              console.log("THIS IS THE TELCO PAYLOAD", item);

              const browser = this.getPlatformType();

              const theSource = localStorage.getItem("payment_source");

              let paymentDetailsForGA = {
                free_trial: item.trialperioddays,
                payment_mode: response.data.referencedata.gwprovider,
                plan_id: item.planid,
                plan_name: item.planname,
                payment_id: response.data.referencedata.paymentid,
                source: theSource,
                object_id: "",
                object_name: "",
                platform: this.getPlatform(),
                platform_type: browser.browser,
              };

              // Google analytics Event
              this.subscriptionStarted(paymentDetailsForGA);

              // Facebook Analytics Event
              this.faceBookInitateCheckoutEvent(paymentDetailsForGA);

              let redirectURL = "";
              if (response.data.referencedata.paymenturl) {
                redirectURL =
                  response.data.referencedata.paymenturl +
                  `?&lang=${this.localDisplayLang === "ara" ? "ar" : "en"}`;
              } else if (response.data.referencedata.checkoutUrl) {
                redirectURL = response.data.referencedata.checkoutUrl;
              }
              window.open(redirectURL, "_self");
            }
          })
          .catch((error) => console.log("error", error));
      } else if (
        (item.hasOwnProperty("isOreedo") && item.isOreedo) ||
        (item.hasOwnProperty("isZainIraq") && item.isZainIraq) ||
        (item.hasOwnProperty("isDUUAE") && item.isDUUAE) ||
        (item.hasOwnProperty("isTpay") && item.isTpay) ||
        (item.hasOwnProperty("isEtisalatUAE") && item.isEtisalatUAE) ||
        (item.hasOwnProperty("isZainKsa") && item.isZainKsa) ||
				(item.hasOwnProperty("isPalestine") && item.isPalestine)
      ) {
        let payload = {
          gatewayid: this.operatorGateway.gatewayid,
          params: this.constructInitParams(item),
        };

        this.paymentInitiation(payload)
          .then((response) => {
            this.showLoader = false;
            if (response.data.reason) {
              this.snackbarMessage(response.data.reason);
            } else {
              let payload = {
                paymentInfo: response,
                planInfo: item,
                isMobileno: true,
                gatewayInfo: this.operatorGateway,
                state: true,
              };
              this.$emit("close", payload);
            }
          })
          .catch((error) => console.log("error", error));
      } else {
        const newItem = {
          ...item,
        };

        if (
          item &&
          this.planAndDiscount &&
          item.planid === this.planAndDiscount.planid
        ) {
          newItem.coupon = this.planAndDiscount.coupon;
        }

        this.$emit("subscribe", newItem);
      }
    },
    snackbarMessage(message) {
      // snackbar.show({
      //   text: message,
      //   textColor: "#ffffff",
      //   pos: "bottom-center",
      //   actionTextColor: "#de2600",
      // });

      showSnackBar(this.$t(message));
    },
    planCancel(item) {
      console.log("THE PLAN CANCEL ITEM", item);

      this.$emit("cancel", item);
    },
  },
  beforeDestroy() {
    eventBus.$off("free-subscription-response");
  },
  components: {
    loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
  },
  mixins: [Utility, googleAnalytics, facebookPixelAnalytics],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

apple-pay-button {
  --apple-pay-button-width: 5px;
  --apple-pay-button-height: 5px;
  --apple-pay-button-border-radius: 1 px;
  --apple-pay-button-padding: 0px 1px;
  --apple-pay-button-box-sizing: border-box;
}
//  .apple-pay {
//       padding: 0 0.6rem;
//       //width: 100%;
//     }
.line-through {
	text-decoration: line-through;
	color: #5e5d60;
	text-decoration-color: #5e5d60;
  text-decoration-thickness: 1.6px;
}
.discount {
  //text-decoration: line-through;
  //color: #5e5d60;
  &-text {
    font-size: 0.75rem !important;
    color: #c1c1c1;
  }
}

.plan-disclimar-text {
  font-size: 0.7rem;
  padding: 0rem 0.2rem;
  font-family: $font-regular;
  color: red;
  opacity: 1;
  text-align: center;
}

.discount-text-msg {
  font-family: $font-regular;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0.25rem;
  color: $btn-clr;
}

.plan {
  position: relative;
  transition: all 0.4s ease-in-out;
  &-card {
    display: grid;
    // align-content: space-between;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
    background-color: #0d0d0d;
    height: 475px;
    width: 300px;
    position: relative;
    margin-right: 20px;
    cursor: pointer;
    &-detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      &-interval {
        opacity: 0.5;
        color: #efeff4;
        font-family: $font-regular;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
      }
      &-amount-container {
        display: flex;
        &-label {
          display: flex;
          color: $clr-light-gd1;
          font-family: $font-regular;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 25px;
          text-align: center;
          &-container {
            // margin: 0 5px;
            &-symbol {
              font-size: 22px;
              font-family: $font-regular;
              color: $clr-light-gd1;
              margin-top: 4px;
              margin-right: 5px;
            }
            &-price {
              font-size: 22px;
            }
          }
          &-interval {
            font-size: 0.8rem;
            padding-top: 0.2rem;
          }
          &-interval-ar {
            margin: 0 5px;
            font-size: 0.8rem;
            color: #c1c1c1;
          }
        }
      }
      &-planname {
        color: #efeff4;
        font-family: $font-regular;
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        // padding-top: 1.2rem;
      }
      &-description {
        font-size: 0.9rem !important;
        text-align: center;
        font-family: $font-regular;
        text-transform: capitalize;
        // word-break: break-all;
      }
      &-plantag {
        color: #efeff4;
        font-size: 0.9rem !important;
        text-align: center;
        font-weight: bold;
        position: absolute;
        font-family: $font-regular;
        top: 8.5%;
        left: -10%;
        border-bottom: 1.6rem solid $btn-clr;
        border-left: 1.6rem solid transparent;
        border-right: 1.6rem solid transparent;
        height: 0;
        width: 50%;
        padding: 0rem 1rem;
        transform: rotate(-45deg);
        p {
          line-height: 1.6rem;
        }
      }
      &-plantag-ar {
        color: #efeff4;
        font-size: 0.9rem !important;
        text-align: center;
        font-weight: bold;
        position: absolute;
        font-family: $font-regular;
        top: 8.5%;
        right: -10%;
        border-bottom: 1.6rem solid $btn-clr;
        border-left: 1.6rem solid transparent;
        border-right: 1.6rem solid transparent;
        height: 0;
        width: 50%;
        padding: 0rem 1rem;
        transform: rotate(45deg);
        p {
          line-height: 1.6rem;
        }
      }
    }
    &-features {
      display: flex;
      align-items: center;
      justify-self: center;
      li {
        padding: 5px 0;
        display: flex;
        img {
          width: 12px;
          opacity: 0.5;
        }
        span {
          font-size: 0.6rem;
          padding: 0rem 0.2rem;
        }
      }
    }
    &-trailperiod {
      text-align: center;
      p {
        color: #f24545;
        font-family: $font-regular;
        font-size: 0.7rem;
        font-weight: 400;
      }
    }
    &-paymentMethods {
      text-align: center;
      .stc {
        width: 30px;
      }
      .oreedo {
        width: 80px;
        height: 45px;
      }
      .duuae {
        height: 30px;
        //width: 30px;
      }
      .etisalat {
        height: 45px;
        width: auto;
      }
      .logo_container {
        display: flex;
        justify-content: space-evenly;
				margin-bottom: 6px;
        .paypal_logo {
          width: 80px;
          height: 25px;
        }
        .card_logo {
          width: 35px;
        }
      }
    }
    &-subscribe {
      width: 100%;
      &-cancelled {
        text-align: center;
        .expiry {
          color: #f24545;
          font-family: $font-regular;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 15px;
        }
      }
      &-expiry-title {
        margin: 5px 10px;
        display: flex;
        justify-content: center;
        .expiry {
          color: #f24545;
          font-family: $font-regular;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 15px;
        }
      }
    }
  }
  &-cancel {
    display: block;
    //position: absolute;
    bottom: -30px;
    width: 100%;
    z-index: -1;
    transition: all 0.4s ease-in-out;
    padding-right: 20px;
    &-card {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      text-align: center;
      background-color: #212121;
      padding: 0.7rem;
      p {
        opacity: 0.5;
        color: #efeff4;
        font-family: $font-regular;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1.2px;
        line-height: 15px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  // &:hover {
  //   transform: scale(1.05);
  //   .plan-cancel {
  //     display: block;
  //   }
  // }
}
// .plan-cancel {
//       display: block;
//     }
.bold-text {
  color: $clr-light-gd2;
  font-weight: 600;
}
.light-text {
  color: $clr-popup-para;
  font-size: 0.8em;
  font-family: $font-regular;
}
.plan-cost {
  margin: 0.2em 0em;
  font-size: 1.5em;
}
.plan-name {
  font-size: 0.8em;
}
.expiry-date {
  margin-top: 1.2em;
}
@media only screen and (max-width: 375px) {
  .plan-disclimar-text {
    font-size: 0.3rem;
    padding: 0rem 0.2rem;
    font-family: $font-regular;
    color: red;
    text-align: center;
  }

  .discount-text-msg {
    font-family: $font-regular;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 0rem !important;
  }

  .discount {
   // text-decoration: line-through;

    &-text {
      font-size: 0.5rem !important;
    }
  }

  .plan-card-detail {
    &-plantag {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 5.5%;
      left: -11%;
      border-bottom: 1.2rem solid $btn-clr;
      border-left: 1.2rem solid transparent;
      border-right: 1.2rem solid transparent;
      font-size: 0.6rem !important;
      width: 49%;
      padding: 0rem;
      transform: rotate(-45deg);
      p {
        line-height: 1.6rem;
      }
    }
    &-plantag-ar {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 5.5%;
      right: -11%;
      border-bottom: 1.2rem solid $btn-clr;
      border-left: 1.2rem solid transparent;
      border-right: 1.2rem solid transparent;
      height: 0;
      width: 49%;
      padding: 0rem;
      transform: rotate(45deg);
      p {
        line-height: 1.6rem;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .plan-disclimar-text {
    font-size: 0.3rem;
    padding: 0rem 0.2rem;
    font-family: $font-regular;
    color: red;
    text-align: center;
  }

  .discount-text-msg {
    font-family: $font-regular;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 0rem !important;
  }

  .discount {
    //text-decoration: line-through;
    margin: 0rem !important;
    &-text {
      font-size: 0.5rem !important;
    }
  }

  .plan {
    width: 100%;
    margin: 10px 0;
    &-card {
      height: 275px;
      width: 160px;
      padding: 1rem;
      margin-right: 0;
      &-detail {
        &-interval {
          font-size: 8px;
          line-height: 12px;
        }
        &-amount-container {
          &-label {
            &-container {
              &-symbol {
                font-size: 12px;
                margin-top: 5px;
                margin-right: 2px;
              }
              &-price {
                font-size: 11px;
              }
            }
            &-interval {
              font-size: 11px;
              padding-top: 0;
              // padding-top: 0.2rem;
            }
            &-interval-ar {
              margin: 0 5px;
              font-size: 0.8rem;
              color: #c1c1c1;
            }
          }
        }
        &-planname {
          font-size: 8px;
          line-height: 12px;
        }
        &-description {
          font-size: 0.6rem !important;
        }
        &-plantag {
          color: #efeff4;
          font-size: 0.9rem !important;
          text-align: center;
          font-weight: bold;
          position: absolute;
          font-family: $font-regular;
          top: 4.5%;
          left: -14%;
          border-bottom: 1.2rem solid $btn-clr;
          border-left: 1.2rem solid transparent;
          border-right: 1.2rem solid transparent;
          font-size: 0.6rem !important;
          width: 55%;
          padding: 0rem;
          transform: rotate(-45deg);
          p {
            font-size: 5px;
            line-height: 1.2rem;
          }
        }
        &-plantag-ar {
          color: #efeff4;
          font-size: 0.9rem !important;
          text-align: center;
          font-weight: bold;
          position: absolute;
          font-family: $font-regular;
          top: 6.5%;
          right: -15%;
          border-bottom: 1.2rem solid $btn-clr;
          border-left: 1.2rem solid transparent;
          border-right: 1.2rem solid transparent;
          height: 0;
          width: 60%;
          padding: 0rem;
          transform: rotate(45deg);
          p {
            font-size: 8px;
            line-height: 1.2rem;
          }
        }
      }
      &-features {
        li {
          padding: 0;
          img {
            width: 8px;
            margin-right: 5px;
          }
          span {
            font-size: 0.3rem;
            line-height: 0.7rem;
            margin-left: -5px;
          }
        }
      }
      &-trailperiod {
        text-align: center;
        p {
          color: #f24545;
          font-family: $font-regular;
          font-size: 0.5rem;
          font-weight: 400;
        }
      }
      &-paymentMethods {
        text-align: center;
        .stc {
          width: 20px;
        }
        .logo_container {
          display: flex;
          justify-content: auto;
          flex-wrap: wrap;
          .paypal_logo {
            width: 50px;
            height: 15px;
          }
          .card_logo {
            width: 25px;
          }
        }
      }
      &-subscribe {
        &-btn {
          padding: 0.3rem;
          font-size: 10px;
        }
        &-expiry-title {
          .expiry {
            font-size: 7px;
            line-height: 0;
          }
        }
      }
    }

    .plan-cancel {
      padding-right: 0;
      display: block;
    }
  }
}
@media only screen and (min-width: 426px) and (max-width: 576px) {
  .plan-disclimar-text {
    font-size: 0.3rem;
    padding: 0rem 0.2rem;
    font-family: $font-regular;
    color: red;
    text-align: center;
  }

  .discount-text-msg {
    font-family: $font-regular;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin: 0rem !important;
  }

  .plan {
    width: 100%;
    &-card {
      height: 300px;
      margin-right: 0;
      &-detail {
        &-amount-container {
          &-label {
            &-container {
              &-symbol {
                font-size: 10px;
                margin-top: 2px;
              }
              &-price {
                font-size: 10px;
              }
            }
            &-interval {
              font-size: 0.8rem;
              padding-top: 0.2rem;
            }
            &-interval-ar {
              padding-top: 0.4rem;
              font-size: 0.7rem;
              color: #c1c1c1;
            }
          }
        }
        &-description {
          font-size: 0.6rem !important;
        }
        &-plantag {
          border-bottom: 1rem solid $btn-clr;
          border-left: 1rem solid transparent;
          border-right: 1rem solid transparent;
          font-size: 0.6rem !important;
          width: 44%;
          top: 5.5%;
          left: -10%;
          padding: 0rem 1rem;
          p {
            line-height: 1rem;
          }
        }
      }
      &-features {
        li {
          padding: 0;
          img {
            width: 8px;
            margin-right: 5px;
          }
          span {
            font-size: 0.4rem;
            line-height: 0.7rem;
            margin-left: -5px;
          }
        }
      }
      &-trailperiod {
        text-align: center;
        p {
          color: #f24545;
          font-family: $font-regular;
          font-size: 0.5rem;
          font-weight: 400;
        }
      }
      &-paymentMethods {
        text-align: center;
        .stc {
          width: 20px;
        }
        .logo_container {
          display: flex;
          justify-content: space-evenly;
          .paypal_logo {
            width: 50px;
            height: 15px;
          }
          .card_logo {
            width: 25px;
          }
        }
      }
      &-subscribe {
        &-btn {
          padding: 0.4rem;
          font-size: 0.6rem;
        }
      }
    }
  }
}
@media only screen and (min-width: 577px) {
  .plan-card-detail {
    &-description {
      font-size: 0.7rem !important;
    }
    &-plantag {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 5.5%;
      left: -14%;
      border-bottom: 1.6rem solid $btn-clr;
      border-left: 1.6rem solid transparent;
      border-right: 1.6rem solid transparent;
      font-size: 0.9rem !important;
      width: 60%;
      padding: 0rem 1rem;
      transform: rotate(-45deg);
      p {
        font-size: 12px;
        line-height: 1.6rem;
      }
    }
    &-plantag-ar {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 5.5%;
      right: -14%;
      border-bottom: 1.6rem solid $btn-clr;
      border-left: 1.6rem solid transparent;
      border-right: 1.6rem solid transparent;
      height: 0;
      width: 60%;
      padding: 0rem 1rem;
      transform: rotate(45deg);
      p {
        line-height: 1.6rem;
      }
    }
  }
}
@media only screen and (min-width: 1024px) {
  .plan-card-detail {
    &-description {
      font-size: 0.8rem !important;
    }
    &-plantag {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 10%;
      left: -15%;
      border-bottom: 1.6rem solid $btn-clr;
      border-left: 1.6rem solid transparent;
      border-right: 1.6rem solid transparent;
      font-size: 0.9rem !important;
      width: 70%;
      padding: 0rem 1rem;
      transform: rotate(-45deg);
      p {
        font-size: 12px;
        line-height: 1.6rem;
      }
    }
    &-plantag-ar {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 10%;
      right: -15%;
      border-bottom: 1.6rem solid $btn-clr;
      border-left: 1.6rem solid transparent;
      border-right: 1.6rem solid transparent;
      height: 0;
      width: 70%;
      padding: 0rem 1rem;
      transform: rotate(45deg);
      p {
        line-height: 1.6rem;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .plan-card-detail {
    &-plantag {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 9%;
      left: -15%;
      border-bottom: 1.6rem solid $btn-clr;
      border-left: 1.6rem solid transparent;
      border-right: 1.6rem solid transparent;
      font-size: 0.9rem !important;
      width: 60%;
      padding: 0rem 1rem;
      transform: rotate(-45deg);
      p {
        font-size: 10px;
        line-height: 1.6rem;
      }
    }
    &-plantag-ar {
      color: #efeff4;
      font-size: 0.9rem !important;
      text-align: center;
      font-weight: bold;
      position: absolute;
      font-family: $font-regular;
      top: 5.5%;
      right: -13%;
      border-bottom: 1.6rem solid $btn-clr;
      border-left: 1.6rem solid transparent;
      border-right: 1.6rem solid transparent;
      height: 0;
      width: 60%;
      padding: 0rem 1rem;
      transform: rotate(45deg);
      p {
        font-size: 10px;
        line-height: 1.6rem;
      }
    }
  }
}
// .yellow-color-btn {
//   border: #e8a622 1px solid !important;
//   color: #e8a622 !important;
// }
// .yellow-color-btn:hover {
//   color: #0d0d0d !important;
//   background: #e8a622 !important;
// }
</style>
