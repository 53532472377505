<template>
  <Popup class="subscribe-popup" :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="subscribe" slot="component">
      <div class="subscribe-title">
        <div class="subscribe-title-subscribe">
          <h2 class="subscribe-title-subscribe-title">{{ $t("start_watching_today") }}</h2>
          <p class="subscribe-title-subscribe-title small">{{ $t("select_your_package") }}</p>
        </div>
        <br />
      </div>
      <div class="subscribe-plan" dir="ltr">
        <div class="subscribe-plan-placeholder" v-if="!isPlans">
          <div
            class="subscribe-plan-placeholder-card loading"
            v-for="(item, index) in placeHolderArr"
            :key="index"
          ></div>
        </div>
        <div
          class="subscribe-plan-card"
          id="subscription-plan-card"
          :class="subscribedPlans.length > 0 ? 'py-b' : 'py-tb'"
          v-else
        >
          <div v-for="(item, index) in availablePlans" :key="index" class="subscribe-plan-card-item">
            <planCard
              :plan="item"
              :gatewayList="gatewayResponse"
              @subscribe="subscribe"
              @cancel="planCancel"
              @close="(response) => closeSubscribeAndOpenOTP(response)"
              :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
            ></planCard>
          </div>
           <!-- coupon-code -->
        <div v-if="!subscriptionList.length > 0" class="voucher-container">
          <h2>{{ $t("Have a Voucher? Redeem Now") }}</h2>
          <button class="voucher-button" @click="voucherInit">
            {{ $t("Noorplay Voucher") }}
          </button>
        </div>
        <!-- couponText -->
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import { store } from "@/store/store";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    subscribePayload: {
      type: Object,
    },
  },
  data() {
    return {
      width: "68%",
      margin: "8% auto",
      radiusType: "full",
      isPlans: false,
      plans: [],
      availablePlans: [],
      filteredAvailablePlans: [],
      subscribeORPurchase: false,
      slider: null,
      placeHolderArr: [1, 2],
      subscribedPlans: [],
      planSelectedForCancellation: null,
      localDisplayLang: null,
      gatewayResponse: [],
      currentPlanSelected: null
    };
  },
  computed: {
    ...mapGetters(["availabilityList", "subscriptionList", "subscriberid", "profileid" , "appConfig", "getPlanDeeplinkPath"]),
  },
  watch: {
    plans(val) {
      if (val.length > 0) {
        val.forEach((element) => {
          if (element.paymentoptions.includes("OPERATOR")) {
            this.checkGatewayForOperator(this.gatewayResponse, element);
          }
        });
      }
    },

    subscriberid(val) {
      console.log("THE TOKEN LOGG --> ", this.subscriptionList);
      if(val && val === this.profileid && this.currentPlanSelected && this.subscriberid === this.profileid && !this.subscriptionList) {
        this.subscribe(this.currentPlanSelected);
      }
    }
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    //listing all the plans for subscription.
    if (
      (this.subscribePayload.listType && this.subscribePayload.listType === "onlySubscribe") ||
      this.subscribePayload.listPlans === "ALL"
    ) {
      //list gateways.
      if(this.subscriberid) {
        eventBus.$emit("listGateway");
      } else {
        this.getExternalGateways();
      }
    }
  },
  mounted() {
    if (screen.width < 576) {
      this.width = "20%";
    }

    eventBus.$emit("close-detail-popup", false);

    //event for fetching available plans for given availability id.
    eventBus.$on("planlist-subscriptions-response", (response) => {
      if (!response.reason) {
        this.plans = response.data;
        console.log("PLANLIST RESPONSE=====>", this.plans, this.subscribePayload);

        if (this.subscribePayload.listType === "onlySubscribe") {
          this.planSubscriptionList();
        } else if (!this.subscribeORPurchase && this.subscribePayload.listType === "Availabilities") {
          //fetch only available plans matching the props availabilities.
          this.fetchAvailablePlans();
        } 
        
        // else {
        //   this.isPlans = true;
        //   this.availablePlans = response.data;

        //   this.availablePlans.forEach((element) => {
        //     element.buttonStatus = "Subscribe";
        //   });

        //   // this.fetchAvailablePlans();
        // }
      }
    });

    //event for fetching subscribed plans
    eventBus.$on("subscription-response", (payload) => {
      if (!payload.reason) {
        this.subscribedPlans = payload.data;
        //update subscription list response to store.
        store.commit("setSubscriptionList", this.subscribedPlans);
        this.mergeSubscribedPlans();
      } else {
        this.plans.forEach((plan) => {
          plan.buttonStatus = "Subscribe";
        });
        this.showPlanList();
      }
    });

    //event for subscription update
    eventBus.$on("subscriptionUpdate-response", (response) => {
      if (!response.reason) {
        this.subscribePlan();
        this.planSubscriptionList();

        //Subscription Updated event for cancel.
        if (this.planSelectedForCancellation) {
          let data = {
            Status: "cancelled",
            Subscription_Start_Date: this.planSelectedForCancellation.planstartdate,
            Subscription_End_Date: this.planSelectedForCancellation.nextbilling,
            Subscription_Type: this.planSelectedForCancellation.renewtype,
            Plan_Name: this.planSelectedForCancellation.planname,
            // Object_ID: ""
          };

          //clevertap event
          this.subscriptionUpdatedCleverEvent(data);

          //google analytics event
          this.subscriptionCancelled(data);
        }
      }
    });

    eventBus.$on("listgateway-response", (response) => {
      this.gatewayResponse = response.data;
      this.subscribePlan();
    });
  },
  methods: {
    ...mapActions(["availabilityDetail", "listExternalGateways", "getPlan"]),
    closeSubscribeAndOpenOTP(response) {
      eventBus.$emit("telcoOtpPopup", response);
      this.closePopup();
    },
    voucherInit() {
      this.$router.push({ name: "voucher" });
    },

    getExternalGateways() {
      this.listExternalGateways().then((response) => {
        console.log("THE EXTERNAL GATEWAY RESPONSE -- > ", response.data);
        this.gatewayResponse = response.data;
        this.subscribePlan();
      });
    },

    //only for subscription.
    subscribePlan() {
      let payload = {
        planvisibility: "YES",
        displaylanguage: this.localDisplayLang,
      };
      eventBus.$emit("planList_subscriptions", payload);
    },

    planSubscriptionList() {
      let payload = {
        subscriptionstatus: "ALL",
      };
      eventBus.$emit("subscriptionList", payload);
    },

    checkGatewayForOperator(operators, plan) {
      if (operators.length > 0) {
        let gateways = operators.filter((el) => {
          return el.gwmode == "OPERATOR";
        });

        let index = gateways.findIndex((element) => {
          return element.ApplicaplePlanList.length > 0 && element.ApplicaplePlanList.includes(plan.planid);
        });

        if (index > -1) {
          switch (gateways[index].gatewayid) {
            case "TELCOSTCBH":
              plan.isSTC = true;
              break;
            case "TELOOREDOOQT":
              plan.isOreedo = true;
              break;
            case "TELOOREDOOOM":
              plan.isOreedo = true;
              break;
            case "TIMWEDUUAE":
              plan.isDUUAE = true;
              break;
            case "TELCOZAINKWT":
              plan.isZainKwt = true;
              break;
            case "TELCOZAINJOR":
              plan.isZainJod = true;
              break;
            case "ETISALETUAE":
              plan.isEtisalatUAE = true;
              break;
              case "TELCOZAINIRQ":
              plan.isZainIraq = true;
              break;
						case "TELCOREDOOPS":
              plan.isPalestine = true;
              break;
            case "TELCOZAINKSA":
              plan.isZainKsa = true;
          }
        }
      }
    },

    showPlanList() {
			let localPlanid = localStorage.getItem("planId");
      this.availablePlans = [];
			if(localPlanid){
			 this.plans.forEach((plan) => { 
					if(plan.planid === localPlanid){						
					console.log("NEW-PLANS",plan)
						this.availablePlans.push(plan)
					}

		});				
		if(this.availablePlans.length <= 0) {
			let payload = {
				planid: localPlanid,
			};
			this.getPlan(payload)
				.then((response) => {
						if (!response.reason) {
						let plan = response.data;
						plan.buttonStatus = "Subscribe";
						this.availablePlans.push(plan)
						console.log("response of getplan api", plan);
					}
				})
			.catch((error) => console.log("planError", error));
		}
			}else {
				this.availablePlans = this.plans;
			}
      this.isPlans = true;

      setTimeout(() => {
        let planCard = document.getElementById("subscription-plan-card") || null;
        planCard.scrollLeft = 0;
        if (screen.width >= 1440) {
          planCard.scrollLeft = planCard.clientWidth / 2 - 220;
        } else if (screen.width < 1440) {
          planCard.scrollLeft = planCard.clientWidth / 2 - 150;
        }
				localStorage.removeItem("planId");
      }, 200);			 
    },

    mergeSubscribedPlans() {
      this.responseError = "";
      this.availablePlans = [];
      this.subscribedPlans.forEach((subscribed) => {
        let index = this.plans.findIndex((element) => {
          return element.planid === subscribed.planid;
        });
        if (index > -1) {
          if (subscribed.subscriptionstatus === "ACTIVE" || subscribed.subscriptionstatus === "ONTRIAL") {
            this.plans[index].buttonStatus = null;
            this.plans[index].subscriptionStatus = subscribed.subscriptionstatus;
            this.plans[index].expiry = subscribed.nextbilling;
          } else if (subscribed.subscriptionstatus === "CANCELLED") {
            if (!this.plans[index].paymentoptions.includes("OPERATOR")) {
              this.plans[index].buttonStatus = "REACTIVATE";
            }
            this.plans[index].subscriptionStatus = subscribed.subscriptionstatus;
            this.plans[index].expiry = subscribed.nextbilling;
          }
          this.availablePlans.push(this.plans[index]);
        }

        this.isPlans = true;
      });
    },

    //check for available plans.
    fetchAvailablePlans() {
      this.subscribePayload.availabilities.forEach((availability) => {
        this.plans.forEach((plan) => {
          if (plan.availabilityset.includes(availability.availabilityid)) {
            this.filteredAvailablePlans.push(plan);
          }
        });
      });

      this.availablePlans = this.filteredAvailablePlans;
      console.log("available plans", this.availablePlans);

      this.availablePlans.forEach((element) => {
        element.buttonStatus = "Subscribe";
      });

      this.isPlans = true;
    },

    subscribePlanFromSubscribeOrPurchase() {
      this.subscribeORPurchase = false;
      this.isPlans = false;
      this.listPlans();
    },

    //main button actions
    subscribe(plan) {
      this.currentPlanSelected = plan;
      console.log("TTHE LOGIN SUCCESS MESSAGE ---- THE RECAL LOG > ", plan)
      if (plan.buttonStatus === "Subscribe") {
        if (this.subscriberid) {
          this.closePopup();

          let payload = {
            state: true,
            detail: plan,
            gateways: this.gatewayResponse,
            transactionPurpose: "SUBSCRIPTION",
            coupon: plan.coupon ? plan.coupon : null
          };
          eventBus.$emit("enableOverlayLoader", true);
          setTimeout(() => {
            eventBus.$emit("paymentPopup", payload);
            eventBus.$emit("enableOverlayLoader", false);
          },500)
          // eventBus.$emit("noorPayments", payload);
        } else {
          // Open lookup popup.
          let payload = {
            state: true,
            formType: "lookup",
          };

          console.log("The DEEPLING PATH PRESEVE -- > ", this.$router.currentRoute.fullPath);

          eventBus.$emit("authPopup", payload);

        }
      } else if (plan.buttonStatus === "REACTIVATE") {
        this.isPlans = false;
        let payload = {
          planid: plan.planid,
          params: {
            planstatus: "ACTIVE",
          },
        };
        eventBus.$emit("subscription-update", payload);
      }
    },

    planCancel(plan) {
      this.planSelectedForCancellation = null;
      let index = this.subscriptionList.findIndex((el) => {
        return el.planid === plan.planid;
      });
      if (index > -1) {
        this.planSelectedForCancellation = this.subscriptionList[index];
      }
      let payload = {
        planid: plan.planid,
        params: {
          planstatus: "CANCEL",
        },
      };
      this.isPlans = false;
      eventBus.$emit("subscription-update", payload);
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    planCard: () => import(/* webpackChunkName: "plancard" */ "@/components/Templates/planCard.vue"),
  },
  mixins: [Utility, googleAnalytics, cleverTap],
  beforeDestroy() {
    eventBus.$off("listgateway-response");
    eventBus.$off("planlist-subscriptions-response");
    eventBus.$off("subscription-response");
    eventBus.$off("subscriptionUpdate-response");
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.subscribe {
  padding: 3rem 5rem;
  &-title {
    &-subscribe {
      &-title {
        color: #efeff4;
        font-family: $font-regular;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
    &-subscribe-purchase {
      text-align: center;
      &-title {
        color: #efeff4;
        font-family: $font-regular;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
  }
  &-plan {
    .voucher-container {
      font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
        color: #ffffff;
        padding: 2rem;
        border-radius: 10px;
        background-color: #0d0d0d;
        height: 150px;
        width: 300px;
        position: relative;
        margin-right: 20px;
        cursor: pointer;

        h2 {
          font-size: 0.8rem;
          margin-bottom: 1rem;
          font-weight: 600;
          display: flex;
        }

        .voucher-button {
          display: flex;
          background-color: #1c1c1e;
          color: #ffffff;
          font-size: 0.9rem;
          font-weight: 600;
          padding: 10px 20px;
          border: 2px solid #560C1D;
          border-radius: 8px;
          cursor: pointer;
          outline: none;
          margin-top: 33px;
          width: 14.5rem;
        }

        .voucher-button:hover {
          background-color: #560C1D;
          color: #ffffff;
        }
      }
    &-placeholder {
      display: grid;
      justify-content: space-evenly;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      // margin: 15px;
      margin-top: 20px;
      &-card {
        background-color: #0d0d0d;
        border-radius: 10px;
        height: 400px;
        overflow: hidden;
      }
    }
    &-card {
      display: flex;
      overflow-x: auto;
      // padding-bottom: 40px;
    }
    &-plancard {
      margin-top: 20px;
      // #slider-carousel {
      //   // padding-bottom: 3rem;
      //   // padding-left: 0.5rem;
      //   // padding-right: 0.5rem;
      // }
    }
  }
  &-purchase {
    &-planbutton {
      margin: 40px 0;
      display: flex;
      justify-content: center;
      &-plan {
        width: 50%;
      }
    }
    &-or {
      text-align: center;
      margin: 40px 0;
      opacity: 0.5;
      color: #ffffff;
      font-family: $font-regular;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
    }
    &-pricelisterror {
      width: 50%;
      padding-bottom: 20px;
      margin: 0 auto;
    }
    &-placeholder {
      &-card {
        height: 100px;
        width: 100px;
        background: black;
      }
    }
    &-pricelist {
      width: 50%;
      margin: 0 auto;
    }
    &-single-button {
      display: flex;
      justify-content: center;
      margin: 40px 0;
      &-purchase {
        width: 50%;
      }
    }
  }
  .small {
    font-size: 12px;
    margin-top: 10px;
  }
  .plans-carousel {
    position: relative;
    .slider-head {
      display: grid;
      justify-content: end;
      .slider-head-btns {
        .slider-controls {
          .prev {
            position: absolute;
            top: 40%;
            left: -8%;
          }
          .forward {
            position: absolute;
            top: 40%;
            right: -8%;
          }
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            img {
              height: 50px;
              width: 50px;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .slider-content {
      padding: 10px;
      // .card {
      //   height: 90px;
      //   border-radius: 5px;
      //   background-repeat: no-repeat;
      //   background-position: center;
      //   background-size: center;
      //   opacity: 0.6;
      //   transition: all 0.4s ease-in-out;
      //   &:hover {
      //     opacity: 1;
      //     transform: scale(1.2);
      //   }
      // }
    }
  }
  .pricelist-card {
    border: 1px solid #5c5c5d;
    opacity: 0.5;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    transition: all 0.3s ease-in-out;
    margin: 1rem 0rem;
    .tick {
      display: none;
      position: absolute;
      top: 15px;
      right: 20px;
      width: 15px;
    }
    .pricetag {
      display: flex;
      .hd {
        margin-right: 5px;
      }
      .pricelist-title {
        color: #ffffff;
        font-family: $font-regular;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 23px;
      }
    }
    .pricelist-text {
      color: #ffffff;
      font-family: $font-regular;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 12px;
    }
    &:hover {
      opacity: 1;
      border: 1px solid #efeff4;
      border-radius: 5px;
      background-color: #0d0d0d;
      transform: scale(1.05);
    }
  }
}

.py-b {
  padding-bottom: 40px;
}

.py-tb {
  padding: 10px 0;
}

@media only screen and (max-width: 576px) {
  .subscribe-popup {
    display: flex;
    align-items: center;
  }
  .subscribe {
    padding: 2rem;
    &-title {
      &-subscribe {
        &-title {
          font-size: 18px;
        }
      }
    }
    &-plan {
      // margin: 0 25px;
      margin-right: 20px;
      &-placeholder {
        grid-template-columns: repeat(1, 1fr);
        &-card {
          height: 220px;
        }
      }
      &-card {
        &-item {
          margin-right: 20px;
        }
      }
      &-plancard {
        .plans-carousel {
          .slider-head {
            .slider-head-btns {
              .slider-controls {
                .prev {
                  left: -18%;
                }
                .forward {
                  right: -18%;
                }
                button {
                  img {
                    width: 35px;
                    height: 35px;
                  }
                }
              }
            }
          }
          .slider-content {
            padding: 0;
          }
        }
      }
    }
    &-purchase {
      &-pricelist {
        width: 85%;
        margin: 0 auto;
      }
      &-single-button {
        display: flex;
        justify-content: center;
        margin: 40px 0;
        &-purchase {
          width: 85%;
        }
      }
    }
  }
}
</style>
